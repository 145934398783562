
import logo from '../img/logo.png';
import arrow from '../img/arrow.svg';

export default function SuccessScreen({ mode = 0, callback }) {

    return (
        <div className="blurred-screen success">
            <div>
                {
                    {
                        0: (
                            <>
                                <h4 style={{ marginBottom: "25px" }}>All done!</h4>
                                <h1 style={{ margin: 0 }}>Your MeID token has been minted successfully!</h1>
                                <br/><br/>
                                <button className="btn-primary" onClick={callback} disabled={false}>
                                    <span className="text">Continue</span>
                                    <div className="btn-primary-icon">
                                        <div className="circle"></div>
                                        <img src={arrow} alt="" />
                                    </div>
                                </button>


                            </>
                        )
                    }[mode]
                }
            </div>
            <img src={logo} className="logo-bottom" alt="Demos" />
        </div>
    )
}

