import { Link } from "react-router-dom";

export default function Footer() {

  return (

    <footer>
      <span>&copy; Copyright 2024 <Link to="https://www.demos.global/">Demos Global</Link></span>
    </footer>

  );
}