
import logo from '../img/logo.png';

export default function WaitingScreen({ mode = 0 }) {

    return (
        <div className="blurred-screen">
            <div>
                {
                    {
                        0: (
                            <h1>Loading...</h1>
                        ),
                        1: (
                            <>
                                <div className="dot-animation">
                                    <div className="dot"></div>
                                    <div className="dot"></div>
                                    <div className="dot"></div>
                                </div>
                                <h4 style={{ marginBottom: "25px" }}>Waiting for confirmation</h4>
                                <h1 style={{ margin: 0 }}>Confirm the transaction in your wallet</h1>
                            </>
                        )
                    }[mode]
                }
            </div>
            <img src={logo} className="logo-bottom" alt="Demos" />
        </div>
    )
}

