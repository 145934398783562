import { Link } from 'react-router-dom';

import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

import ellipse from '../img/ellipse.svg';
import lines from '../img/bg-lines.svg';

import arrow from '../img/arrow.svg';
import lock from '../img/lock.svg';
import dashboardIcon from '../img/dashboard.svg';
import analyticsIcon from '../img/analytics.svg';
import ecosystemIcon from '../img/ecosystem.svg';

export default function Home() {

  return (
    <>
      <Navbar />

      <div className="background-elements">
        {
          <>
            <img src={lines} alt="" className="lines" />
            <img src={ellipse} alt="" className="ellipse-3" />
          </>
        }
      </div>

      <div className="content-wrapper landing-page">
        <h1 className="header-big">
          Choose your path
        </h1>

        {/* The css for buttons in this list is set up to allow both <button> elements as well as <a> (<Link>) */}
        <Link to="/dashboard" className="btn-setup active" disabled={false}>
          <img src={dashboardIcon} alt="" className="btn-icon" />
          <span className="text-big">Dashboard</span>
          <span className="text-small">Enroll and access the Demos dashboard</span>
          <div className="btn-setup-icon">
            <div className="circle"></div>
            <img src={arrow} alt="" />
          </div>
        </Link>
        <button className={"btn-setup locked"} onClick={null} disabled={true}>
          <img src={analyticsIcon} alt="" className="btn-icon" />
          <span className="text-big">Analytics</span>
          <span className="text-small">Access the analytics of our ecosystem</span>
          <div className="btn-setup-icon">
            <div className="circle"></div>
            <img src={lock} alt="" />
          </div>
        </button>
        <Link to="https://demosglobal.notion.site/Demos-Ecosystem-Exploration-a0366f5ad2964acca58218bb1b625fe8" target="_blank" className="btn-setup active" disabled={false}>
          <img src={ecosystemIcon} alt="" className="btn-icon" />
          <span className="text-big">Ecosystem</span>
          <span className="text-small">Explore the Demos ecosystem</span>
          <div className="btn-setup-icon">
            <div className="circle"></div>
            <img src={arrow} alt="" />
          </div>
        </Link>
        <br />
      </div >

      <Footer />
    </>
  );
}