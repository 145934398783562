import { mainnet, goerli, polygon, base, baseSepolia } from 'wagmi/chains';
import { defineChain } from 'viem';

import MeIDABI from './ABI/MeID.json';

export const TESTMODE = false;

export const blast = defineChain({
  id: 81457,
  name: 'Blast',
  nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
  rpcUrls: {
    default: { http: ['https://rpc.blast.io'] },
  },
  blockExplorers: {
    default: { name: 'Blastscan', url: 'https://blastscan.io/' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 88189,
    },
  },
})

export const blastTest = defineChain({
  id: 168587773,
  name: 'Blast Test',
  nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
  rpcUrls: {
    default: { http: ['https://sepolia.blast.io'] },
  },
  blockExplorers: {
    default: { name: 'Blastscan', url: 'https://testnet.blastscan.io/' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 756690,
    },
  },
})

export const ADDRESSES = {
  [mainnet.id]: {
    MeID: "0xCB23d7B889140C9cFf1317FcbeF9A23eDcb1D4BB",
  },
  [goerli.id]: {
    MeID: "0x388760A54b5B070A391870A1e35bcd3dB65D7bF2",
  },
  [baseSepolia.id]: {
    MeID: "0x60622efc64f4b50b3b91d5c8168a50d9f2dfea4e",
  },
  [blastTest.id]: {
    MeID: "0x9FA420818AdbD692c243B898E597bb63e6f0b268",
  },
  [polygon.id]: {
    MeID: "0x70468F06CF32B776130E2Da4C0D7DD08983282EC",
  },
  [blast.id]: {
    MeID: "0x70468F06CF32B776130E2Da4C0D7DD08983282EC",
  },
  [base.id]: {
    MeID: "0x70468F06CF32B776130E2Da4C0D7DD08983282EC",
  }
};

export const ABIS = {
  MeID: MeIDABI,
};

export const API = {
  KEY: 'b653e95d-a6b9-4b2b-b299-1b223e39cdd8',
  URI: TESTMODE ? 'https://apidevelop.demos.global' : 'https://api.demos.global',
}

export const NETWORKCODENAMES = {
  [baseSepolia.id]: "BASESEPOLIA",
  [blastTest.id]: "BLASTTEST",
  [blast.id]: "BLAST",
  [polygon.id]: "POLYGON",
  [base.id]: "BASE"
}

export const NETWORKSBYID = {
  [baseSepolia.id]: baseSepolia,
  [blastTest.id]: blastTest,
  [blast.id]: blast,
  [polygon.id]: polygon,
  [base.id]: base
}

export const NETWORKURLS = {
  [baseSepolia.id]: "/dashboard/basesepolia",
  [blastTest.id]: "/dashboard/blasttest",
  [blast.id]: "/dashboard/blast",
  [polygon.id]: "/dashboard/polygon",
  [base.id]: "/dashboard/base"
}

//export const NETWORKS = [polygon, blast, base, blastTest, baseSepolia];
//export const NETWORKIDS = [polygon.id, blast.id, base.id, blastTest.id, baseSepolia.id];

export const NETWORKS = TESTMODE ? [polygon, blast, base, blastTest, baseSepolia] : [polygon, blast, base];
export const NETWORKIDS = TESTMODE ? [polygon.id, blast.id, base.id, blastTest.id, baseSepolia.id] : [polygon.id, blast.id, base.id];
