import { useState, useEffect } from 'react';
import { useNetwork, useSwitchNetwork } from 'wagmi';
import { useNavigate } from "react-router-dom";
import { polygon, base } from 'wagmi/chains';
import { blastTest, blast, API, ADDRESSES, ABIS, NETWORKS, NETWORKSBYID, NETWORKCODENAMES, NETWORKURLS, TESTMODE } from "../config";

import logo from '../img/logo.png';
import arrow from '../img/arrow.svg';

import logoPolygon from '../img/logos/polygon.svg';
import logoEth from '../img/logos/eth.svg';
import logoBsc from '../img/logos/bsc.svg';
import logoBlast from '../img/logos/blast.svg';
import logoBase from '../img/logos/base.svg';

export default function NetworkPicker({ callback }) {

    const { chain } = useNetwork();
    const { switchNetwork } = useSwitchNetwork();

    const navigate = useNavigate();

    const [initialChainId, setInitialChainId] = useState(null);
    const [pickedNetwork, setPickedNetwork] = useState(null);
    const [hasUserSwitched, setHasUserSwitched] = useState(false);


    useEffect(() => {
        console.log("pickedNetwork", pickedNetwork);
        console.log("chain", chain);
        if (initialChainId === null && chain !== undefined && chain?.id > 0) {
            setInitialChainId(chain.id);
        }

        if (chain === undefined) return;
        if (pickedNetwork === chain?.id) {
            setHasUserSwitched(true);
            if (initialChainId !== pickedNetwork) {
                navigate(NETWORKURLS[pickedNetwork]);
            }
        } else {
            setHasUserSwitched(false);
        }
    }, [pickedNetwork, chain]);

    async function selectNetwork(networkId) {
        setPickedNetwork(networkId);
        try {
            switchNetwork(networkId);
        } catch (e) {
            console.error(e);
            toast.error("Couldn't switch to network id " + networkId + ". Please make sure it's added in your wallet");
        }
    }

    return (
        hasUserSwitched ? null : (
            <div className="blurred-screen">
                <div>
                    <div className="network-picker">
                        {/* <h2>Choose the chain to mint the token on:</h2> */}
                        <h2>Please select a chain:</h2>
                        <button className="btn-select-network" onClick={() => selectNetwork(polygon.id)}><img src={logoPolygon} alt="" /><span>Polygon</span></button>
                        <button className="btn-select-network" onClick={() => selectNetwork(base.id)}><img src={logoBase} alt="" /><span>Base</span></button>
                        <button className="btn-select-network" onClick={() => selectNetwork(blast.id)}><img src={logoBlast} alt="" /><span>Blast</span></button>
                    </div>
                </div>
                <img src={logo} className="logo-bottom" alt="Demos" />
            </div>
        )
    )
}

