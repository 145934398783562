import { useState, useEffect } from 'react';

import logo from '../img/logo.png';
import arrow from '../img/arrow.svg';
import arrowWhite from '../img/arrow-white.svg';
import warning from '../img/warning.svg';


function secondsToTimeString(seconds) {

    var days = Math.floor(seconds / (1000 * 60 * 60 * 24));
    var hours = Math.floor((seconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var minutes = Math.floor((seconds % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((seconds % (1000 * 60)) / 1000);


    let timeString = "";

    if (days > 0) {
        timeString += days + "D : ";
    }
    if (hours > 0) {
        timeString += hours + "H : ";
    }
    if (minutes > 0) {
        timeString += minutes + "M : ";
    }
    if (seconds > 0) {
        timeString += seconds + "S";
    }

    return timeString;
}

export default function WarningScreen({ mode = 0, callback, callbackConfirm, burnAllowedTime }) {

    const [timeLeft, setTimeLeft] = useState("loading...");
    const [burnActive, setBurnActive] = useState(false);

    useEffect(() => {
        if(isNaN(burnAllowedTime)) {
            return;
        }

        if (burnAllowedTime > Date.now()) {

            let interval = setInterval(function () {

                let now = Date.now();
                let distance = burnAllowedTime - now;
                let newCountdown = secondsToTimeString(distance);

                setTimeLeft(newCountdown);

                if (distance < 0) {
                    clearInterval(interval);
                    setBurnActive(true);
                }
            }, 1000);
        } else {
            setBurnActive(true);
        }

    }, [burnAllowedTime]);

    return (
        <div className="blurred-screen warning">
            <div>
                {
                    {
                        0: (
                            <>
                                <img src={warning} alt="" className="icon-warning" />
                                <h1 style={{ marginBottom: "43px" }}>Are you sure?</h1>
                                <h2 style={{ margin: 0 }}>Burning your MeID Token will revoke your access to all your MeID-protected services and allow you to remove all your biometric information.</h2>
                                <br /><br />

                                <button className="btn-primary reversed" onClick={callback}>
                                    <div className="btn-primary-icon">
                                        <div className="circle"></div>
                                        <img src={arrow} alt="" />
                                    </div>
                                    <span className="text">No, take me back</span>
                                </button>

                                <button className="btn-text" onClick={callbackConfirm} disabled={!burnActive}>
                                    <span className="text">Yes, burn my token</span>
                                    <div className="btn-text-icon">
                                        <img src={arrowWhite} alt="" />
                                    </div>
                                </button>

                                {
                                    burnActive ? null : (
                                        <div className="countdown-wrapper">
                                            Burn active in:<br/>
                                            <span>{timeLeft}</span>
                                        </div>
                                    )
                                }

                            </>
                        ),
                        1: (
                            <>
                                <img src={warning} alt="" className="icon-warning" />
                                <h1 style={{ marginBottom: "43px" }}>Deactivating account</h1>
                                <h2 style={{ margin: 0 }}>For security reasons, the account purge consists of two steps. In this first step you'll deactivate your account. To complete the purge, you'll need to return to this page in 24 hours and follow the instructions.</h2>
                                <br /><br />

                                <button className="btn-primary reversed" onClick={callback}>
                                    <div className="btn-primary-icon">
                                        <div className="circle"></div>
                                        <img src={arrow} alt="" />
                                    </div>
                                    <span className="text">Take me back</span>
                                </button>

                                <button className="btn-text" onClick={callbackConfirm}>
                                    <span className="text">Deactivate account</span>
                                    <div className="btn-text-icon">
                                        <img src={arrowWhite} alt="" />
                                    </div>
                                </button>

                            </>
                        )
                    }[mode]
                }
            </div>
            <img src={logo} className="logo-bottom" alt="Demos" />
        </div>
    )
}

